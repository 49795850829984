<script setup lang="ts">
import { joinPath, parseSizeToGrid } from '@manager'
import type {
  IManagerFieldCollectionEmits,
  IManagerFieldCollectionProps,
} from './types'

const props = withDefaults(defineProps<IManagerFieldCollectionProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldCollectionEmits>()

const path = computed(() => joinPath(props.parentPath, props.node.name))

const colSize = computed(() => parseSizeToGrid(props.node.size))

const parentValue = useVModel(props, 'parentValue', emit, {
  passive: true,
  deep: true,
})

// Set default value
if (!parentValue.value[props.node.name]) {
  parentValue.value[props.node.name] = {}
}
</script>

<template>
  <!-- Group -->
  <div :class="[colSize, 'relative space-y-2']" :data-path="path">
    <slot name="header">
      <div v-if="node.label" class="text-base text-white">{{ node.label }}</div>
    </slot>
    <slot name="body">
      <ManagerSharedGroupGrid>
        <slot v-bind="{ path, parentValue }">
          <template
            v-for="childNode in node.nodes"
            :key="resolveNodeId(childNode)"
          >
            <ManagerNode
              v-model:parent-value="parentValue[node.name]"
              :node="childNode"
              :parent-path="path"
              :parent-node="node"
            />
          </template>
        </slot>
      </ManagerSharedGroupGrid>
    </slot>
    <slot name="footer" />
  </div>
</template>
